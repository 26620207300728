<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Module Deactivation</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <place-holder v-if="loading"></place-holder>
                        <div class="table-responsive">
                            <table class="table table-striped dt-responsive w-100 mb-3" id="deactivation-list-datatable"
                                v-show="!loading">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Issued Date</th>
                                        <th>Module Name</th>
                                        <th>Requested</th>
                                        <th>Changed Status By</th>
                                        <th>Status</th>
                                        <th v-if="hasPermissions(['module-group-edit'])">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(moduleDeactivate, index) in deactivateLists" :key="index">
                                        <td>{{ ++index }}</td>
                                        <td>{{ moduleDeactivate.issued_date }}</td>
                                        <td>{{ moduleDeactivate.name }}</td>
                                        <td>{{ moduleDeactivate.requested_by.name }}</td>
                                        <!-- <td>{{ $store.state.auth.user.name }}</td> -->
                                        <td>
                                            <span v-if="moduleDeactivate.approved_or_rejected_by">{{
                                                moduleDeactivate.approved_or_rejected_by.name
                                            }}</span>
                                            <span v-else></span>
                                        </td>
                                        <td>
                                            <span v-if="moduleDeactivate.deactivate_status == 0">Requested</span>
                                            <span v-if="moduleDeactivate.deactivate_status == 2">Rejected</span>
                                            <span v-if="moduleDeactivate.deactivate_status == 1">Approved</span>
                                        </td>
                                        <td v-if="hasPermissions(['module-group-edit'])">
                                            <span>
                                                <router-link class="action-icon" :to="{
                                                    name: 'module-deactivate-update',
                                                    params: { id: moduleDeactivate.id },
                                                }">
                                                    <i class="mdi mdi-square-edit-outline"></i>
                                                    <!-- <button type="button" class="
                                                                btn btn-xs btn-blue
                                                                waves-effect waves-light
                                                                mb-2
                                                                ">
                                                        <i class="fas fa-eye"></i>
                                                        View
                                                    </button> -->
                                                </router-link>
                                            </span>
                                            <!-- <span @click="deleteModuleHandover(moduleDeactivate.id)">
                          <i class="fas fa-trash-alt mx-1"></i>
                        </span> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
//Datatable moduleDeactivate
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
// import moment from "moment";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
    mixins: [userHasPermissions],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            deactivateLists: [],
            loading: false,
            baseUrl: process.env.VUE_APP_BASE_URL,
        };
    },
    methods: {
        async getAllModuleDeactivation() {
            this.loading = true;
            this.$Progress.start();
            await axios
                .get(`${this.baseUrl}admin/v1/module-deactivation-lists`)
                .then((response) => {
                    var lists = response.data.data;
                    if (
                        this.$store.state.auth.user.roles[0] == "Training Head" || this.$store.state.auth.user.roles[0] == "Super Admin") {
                        this.deactivateLists = lists;
                    }
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.toast.error("Something Went Wrong!");
                });
            $("#deactivation-list-datatable").DataTable();
        },
        // async deleteModuleHandover(id) {
        //   axios
        //     .delete(`${this.baseUrl}admin/v1/module-groups/` + id, {})
        //     .then(() => {
        //       this.toast.success("Successfully Module Handover Deleted!");
        //       this.getAllModuleDeactivation();
        //     })
        //     .catch((error) => {
        //       this.errors = error.response.data.errors;
        //       this.toast.error("Something Went Wrong!");
        //     });
        // },
        clearFilter() {
            $.fn.dataTable.ext.search.pop();
            $("#deactivation-list-datatable").DataTable().draw();
        },
    },
    created() {
        this.clearFilter();
        this.getAllModuleDeactivation();
    },
};
</script>

<style>
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4;
}
</style>
  